import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  lookupText: any;

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Wouter',
      subject: 'Dit is een test melding',
      time: '9:30'
    },
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'busy',
      from: 'Test',
      subject: 'Dit is een test melding',
      time: '9:10'
    }
  ];

  onKey(key: any) {
    let route: string;
    
    // Check if input is a valid number
    if (!isNaN(Number(this.lookupText))) {
      route = '/taxatie/detail/' + this.lookupText.replace(/[^\d.-]/g, '');
    }
    // Check if input is alphabetic characters only
    else if (/^[a-zA-Z ]+$/.test(this.lookupText)) {
      route = '/crm/relaties/' + this.lookupText;
    }
    // Check if input starts with 'T' or 't' followed by a number
    else if ((this.lookupText.match(/^t\d/i) !== null && this.lookupText.length != 6)) {
      route = '/taxatie/detail/' + this.lookupText.replace(/[^\d.-]/g, '');
    }
    // Fallback case
    else {
      route = '/taxatie/zoeken/' + this.lookupText;
    }
    
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    this.router.navigateByUrl(route)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
    
    this.lookupText = '';
  }  

  logout() {
    this.authService.logout();
  }
}
