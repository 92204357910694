import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { apiResponse } from 'src/app/models/apiResponse.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { apiAuthModel, authModel, authStorage, authUserModel, storageApiKey } from './models/auth';
import { WebSocketService } from '../modules/general/services/websocket.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    getClients(): Observable<apiResponse> {
        // Create the headers for the request
        const headers = new HttpHeaders().set('Accept', 'application/json');

        // Make the GET request to the server
        return this.http.get<apiResponse>(`${environment.apiUrl}/api/v1/client/`, { headers });
    }

    login(username: string, password: string, remember_me: boolean, deviceToken: string, level: string): Observable<apiAuthModel> {
        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('level', level)
            .append('username', username)
            .append('password', password);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    localStorage.setItem('auth', authStorage.createapiAuth(user, remember_me, deviceToken, username, level));
                    return user;
                })
            );
    }

    loginWithRefreshToken(refreshToken: string): Observable<authModel> {
        // Get the current user from storage
        const oldKey: authModel = JSON.parse(localStorage.getItem('auth') || '{}');

        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .append('refresh_token', refreshToken)
            .append('username', oldKey.username);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    const authModel = authStorage.createapiAuth(user, oldKey.save_login, oldKey.device_token, oldKey.username, oldKey.level);
                    localStorage.setItem('auth', authModel);
                    return JSON.parse(authModel);
                })
            );
    }

    loginWithApiKey(apiKey: string, username: string, deviceToken: string): Observable<authModel> {
        // Get the current user from storage
        const oldKey: authModel = JSON.parse(localStorage.getItem('auth') || '{}');

        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('username', username)
            .append('password', apiKey)
            .append('device_token', deviceToken)
            .append('level', 'key');

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    const authModel = authStorage.createapiAuth(user, oldKey.save_login, oldKey.device_token, oldKey.username, oldKey.level);
                    localStorage.setItem('auth', authModel);
                    return JSON.parse(authModel);
                })
            );
    }

    requestApiKey(deviceToken: string): Observable<any> {
        // Create the body of the request
        const body = new HttpParams().set('device_token', deviceToken);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the POST request to the server
        return this.http.post<any>(`${environment.apiUrl}/api/v1/authentication/key/`, body, { headers })
            .pipe(
                // If the request is successful, store the API key in local storage
                map(api => {
                    localStorage.setItem('apiKey', authStorage.createapiKey(api.data));
                    return api;
                })
            );
    }

    getAuthUserInformation(): Observable<authUserModel> {
        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the GET request to the server
        return this.http.get<any>(`${environment.apiUrl}/api/v1/authentication/`, { headers })
            .pipe(
                // Extract the data from the response
                map(users => users.data)
            );
    }

    updateUserInformation(companyId: number, employeeId: number): Observable<any> {
        // Create the body of the request
        const body = new HttpParams()
            .set('companyId', String(companyId))
            .append('employeeId', String(employeeId));

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the PUT request to the server
        return this.http.put<any>(`${environment.apiUrl}/api/v1/authentication/`, body, { headers })
            .pipe(
                // Extract the data from the response
                map(user => user['data'])
            );
    }

    forgotPassword(username: string, level: string) {
        const body = new HttpParams()
            .set('username', username)
            .append('level', level);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader());

        return this.http.put<any>(`${environment.apiUrl}/api/v1/authentication/forgot/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }

    updateAccount(password: string, passwordCurrent: string) {
        const body = new HttpParams()
            .set('password', password)
            .append('passwordCurrent', passwordCurrent);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        return this.http.put<any>(`${environment.apiUrl}/account/v1/account/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }


    generateDeviceToken(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    checkLoginState() {
        if (localStorage.getItem('auth') !== null) {
            const auth: authModel = JSON.parse(localStorage.getItem('auth') || '{}');
            const apiKey: storageApiKey = JSON.parse(localStorage.getItem('apiKey') || '{}');
            // Check if api key is present
            if (apiKey && apiKey.api_key !== undefined) {
                // Use moment instead of Date for support on mobile browsers
                const apiKeyExpireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date() < apiKeyExpireDate) {
                    // Api key is valid here
                    return true;
                } else {
                    // Api key not valid
                    this.logout();
                    return false;
                }
                // No api key? Let's try the access token
            } else {
                // Use moment instead of Date for support on mobile browsers
                const refreshTokenExpireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date() < refreshTokenExpireDate) {
                    return true;
                } else {
                    this.logout();
                    return false;
                }
            }
            // No auth data in storage? Logout user
        }
        return false;
    }

    checkApiKeyValidity() {
        const apiKey: storageApiKey = JSON.parse(localStorage.getItem('apiKey') || '{}');
        if (apiKey) {
            // Use moment instead of Date for support on mobile browsers
            const expireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
            const now = new Date()
            if (expireDate > now) {
                return true;
            } else {
                return false;
            }
        } else {
            // No API key set.
            return false;
        }
    }

    checkRefreshTokenValidity() {
        const auth: authModel = JSON.parse(localStorage.getItem('auth') || '{}');
        // Use moment instead of Date for support on mobile browsers
        const expireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
        expireDate.setMinutes(expireDate.getMinutes() - 5);
        const now = new Date()
        if (expireDate > now) {
            return true;
        } else {
            return false;
        }
    }

    checkAccessTokenValidity() {
        const auth: authModel = JSON.parse(localStorage.getItem('auth') || '{}');
        // Use moment instead of Date for support on mobile browsers
        const expireDate = moment(auth.access_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
        expireDate.setMinutes(expireDate.getMinutes() - 5);
        const now = new Date()
        if (expireDate > now) {
            return true;
        } else {
            return false;
        }
    }

    getExternalIp(): Observable<{ ip: string }> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<{ ip: string }>('https://api.ipify.org/?format=json', { headers });
    }

    getAuth(): authModel {
        const auth = localStorage.getItem('auth');
        return auth ? JSON.parse(auth) : null;
    }

    encodeBasicHeader(): string {
        return `Basic ${btoa(`${environment.application}:${environment.password}`)}`;
    }

    logout() {
        // Remove the auth data from the local storage
        const offlineData = localStorage.getItem('OfflineData');

        // Clear the entire localStorage
        localStorage.clear();

        if (offlineData) {
            localStorage.setItem('OfflineData', offlineData);
        }

        this.router.navigate(['authentication/login']);
    }
}
