<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<mat-form-field matTooltip="Zoeken op Taxatienummer, Kenteken of Bedrijfsnaam."
    style="width: 150px !important; color:white; font-size: 24px;">
    <input autocomplete="off" style="color:white;" [(ngModel)]="lookupText" (keyup.enter)="onKey($event)" matInput>
</mat-form-field>

<button mat-button id="topmenu" style="color:white;" [matMenuTriggerFor]="message" class="m-t-15">
    <mat-icon>email</mat-icon>
</button>
<mat-menu id="topmenu" style="color:white;" #message="matMenu" class="mymessage">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title">Je hebt {{mymessages.length}} bericht<span
                        *ngIf="mymessages.length > 1">en</span></div>
            </li>
            <li>
                <div class="message-center">
                    <!-- Message -->
                    <a href="#" *ngFor="let mymessage of mymessages">
                        <div class="user-img">
                            <img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">
                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                        </div>
                        <div class="mail-contnet">
                            <h5 style="margin-bottom: 2px; margin-top: 2px;">{{mymessage.from}}</h5>
                            <span class="mail-desc">{{mymessage.subject}}</span><br>
                            <span class="time">{{mymessage.time}}</span>
                        </div>
                    </a>
                    <!-- Message -->
                </div>
            </li>
        </ul>
    </div>
</mat-menu>

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button mat-button style="color:white;" [matMenuTriggerFor]="profile" class="m-r-5 m-t-20">
    <img src="assets/images/users/1.jpg" alt="user" class="profile-pic"> </button>
<mat-menu id="topmenu" style="color:white;" #profile="matMenu" class="mymegamenu">
    <button [routerLink]="['general/instellingen']" mat-menu-item>
        <mat-icon>settings</mat-icon> Instellingen
    </button>
    <button (click)="logout()" mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Uitloggen
    </button>
</mat-menu>